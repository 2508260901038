import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
// import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"
// import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CloseIcon from '@mui/icons-material/Close';
import { fetchAll, update } from "../../../api"
import TableFooter from "@mui/material/TableFooter"
import TablePagination from "@mui/material/TablePagination"
import { KIOSK, BRAND, PRODUCT, PARTNER, OPERATOR } from "../../../utils/constant"
import Checkbox from '@mui/material/Checkbox';
import { MenuItem, Select, Button, FormControl, FormHelperText, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import Loader from "../../../utils/Loader";

const OperatorKioskManagement = () => {
  let navigate = useNavigate()
  const [page, setPage] = React.useState(0)
  const [loading, setLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const [kiosks, setKiosks] = React.useState([])
  const [operators, setOperators] = React.useState([]);
  const [edit, setEdit] = React.useState([false])
  const [opId, setOpid] = React.useState();
  const [skus, setSkus] = React.useState([])
  // const [partner, setPartner] = React.useState();
  const styles = {
    textLeft: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  }

  React.useEffect(() => {
    // getAllBrands()
    // getAllProducts()
    getAllKiosks()
    getAllOperators()
    // getPartners()
  }, [])
  const getAllOperators = async () => {
    try {
      const response = await fetchAll(OPERATOR);
      if (response.data) {
        const data = response.data.data.filter(e => !e.deleted)
        setOperators(data);
      }
      // console.log(response);
    } catch (error) { }
  };
  const getAllKiosks = async () => {
    try {
      setLoading(true);
      const response = await fetchAll(KIOSK)
      if (response.data && response.data.data) {
        let data = response.data.data.map(e => {
          return {
            ...e,
            edit: false
          }
        })
        console.log(data)
        setKiosks(data)
        setLoading(false);
      } else {
        setKiosks([])
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
      setKiosks([])
      setLoading(false);
    }
  }

  const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(value);

  const getOperator = (opid) => {
    if (operators && operators.length) {
      let data = operators.find(e => e.opid == opid)
      return data ? `${data.firstname} ${data.lastname}` : '-'
    }
    return '';
  }

  const editToggle = (ev, i) => {
    console.log(i, ev)
    let index = kiosks.findIndex(e => e._id == ev._id)
    kiosks[index].edit = !kiosks[index].edit
    setKiosks(kiosks)
    setOpid(!kiosks[index].edit)
    // setOperators(operators);
  }
  // const getBrand = (id) => { 
  //   return brands && brands.length && brands.filter((e) => e._id === id)[0].name;
  // };
  const handleSelectOP = (event, data) => {
    console.log(event.target.value, data)
    setOpid(event.target.value)
    let index = kiosks.findIndex(e => e._id == data._id)
    kiosks[index].opid = event.target.value
  };

  const handleDispatch = async (row) => {
    // console.log(row)
    if (row && row.opid) {
      let index = kiosks.findIndex(e => e._id == row._id)
      let payload = {
        opid: kiosks[index].opid
      }
      try {
        const kioskResponse = await update(KIOSK, row._id, payload);
        if (kioskResponse.data && kioskResponse.data.statusCode === 200) {
          toast.success("Success");
          getAllKiosks();
        } else {
          toast.error("Failed");
        }

      } catch (error) { }
    }else {
      toast.error("Operator Name is required.");
      setLoading(false);
    }
  }

  return (
    <div className="container-fluid">
      {loading ? <Loader /> : null}
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col-sm-12">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 550 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Kiosk ID</TableCell>
                  {/* <TableCell>PU Name</TableCell> */}
                  {/* <TableCell>Partner ID</TableCell> */}
                  {/* <TableCell>Brand</TableCell> */}
                  <TableCell style={{ width: "600px" }} >Address</TableCell>
                  <TableCell style={{ width: "150px" }} >Date</TableCell>
                  <TableCell style={{ width: "200px" }} >Operator Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {kiosks && kiosks.length > 0 ? kiosks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                  <TableRow key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
                    <TableCell component="th" scope="row">
                      {row.kid ? row.kid : '-'}
                    </TableCell>
                    {/* <TableCell> {getPartner(row.prnid)} </TableCell> */}
                    {/* <TableCell>
                      {row.prnid?row.prnid:'-'}
                    </TableCell> */}
                    {/* <TableCell>{row.items.length?getBrand(row.items[0].brandid):'-'}</TableCell> */}
                    <TableCell style={{ width: "600px" }} ><span>{`${row.address.address} \n ${row.address.city} \n ${row.address.postalcode} \n ${row.address.state}`}</span></TableCell>
                    <TableCell style={{ width: "150px" }}>{row.rentStartDate ? new Date(row.rentStartDate).toDateString() : '-'}</TableCell>
                    {/* <TableCell>{<NumberFormatBase currency={'USD'} format={row.deposit} />}</TableCell> */}
                    <TableCell style={{ width: "200px" }} >
                      {/* {
                        <Select
                          size="small"
                          placeholder="Select Executive"
                          // value={values.puId}
                          name="executive"
                          onChange={e => { handleSelectOP(e); handleChange(row._id, e) }}
                          // error={errors.puId}
                          displayEmpty
                          style={{ width: "200px" }}
                        >
                          <MenuItem value="">
                            Select Executive
                          </MenuItem>
                          {operator && operator.map((e, keyIndex) => {
                            return (<MenuItem key={keyIndex} value={e.opid}>{`${e.firstname}${e.lastname}`}</MenuItem>);
                          })}
                        </Select>} */}
                      {row.edit ? <Select
                        size="small"
                        placeholder="Select Executive"
                        value={row.opid}
                        name="executive"
                        onChange={e => { handleSelectOP(e, row) }}
                        // error={errors.puId}
                        displayEmpty
                        style={{ width: "200px" }}
                      >
                        <MenuItem value="">
                          Select Executive
                        </MenuItem>
                        {operators && operators.map((e, keyIndex) => {
                          return (<MenuItem key={keyIndex} value={e.opid}>{`${e.firstname} ${e.lastname}`}</MenuItem>);
                        })}
                      </Select> : getOperator(row.opid)} </TableCell>

                    <TableCell>
                      {" "}
                      <IconButton >
                        {row.edit ? <DoneOutlineIcon onClick={() => handleDispatch(row)} /> : <EditIcon onClick={() => editToggle(row, row._id)} />}
                      </IconButton>
                      {/* <IconButton >
                        {row.edit ? <CloseIcon Click={() => editToggle(row, row._id)} /> : ''}
                      </IconButton> */}
                    </TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: "center" }}>
                      <b>No data found</b>
                    </TableCell>
                  </TableRow>}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      20,
                      50,
                      70,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={8}
                    count={kiosks.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div >)
}
export default OperatorKioskManagement

import * as React from "react";
import { TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { fetchAll, update, create, fetchReportData } from "../../api";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { MenuItem, Select, Button, FormControl, FormHelperText, IconButton } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { PROCESSINGUNIT, BRAND, PRODUCT, DISPATCH, KIOSK, PARTNER, OPERATOR } from "../../utils/constant"
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import * as moment from 'moment';
import { toast } from "react-toastify";
import pdfIcon from "../../assets/pdf-icon.png";
import { io } from "socket.io-client";
import Loader from "../../utils/Loader";

const Inventory = () => {
  const socket = io('https://delectable.appswise.tech');
  //Need to update dev url to prod url,need to change in backend also
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [salesReport, setsalesReports] = React.useState([]);
  const [brands, setBrands] = React.useState([])
  const [processingUnits, setProcessingUnits] = React.useState([]);
  const [skus, setSkus] = React.useState([])
  const [puName, setPuName] = React.useState();
  const [opId, setOpid] = React.useState();
  const [partner, setPartner] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [operator, setOperator] = React.useState();
  const [currentDate, setCurrentDate] = React.useState(getDate());
  React.useEffect(() => {

    socket.on("connect")
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
  }, [])


  const reportsValidationSchema = yup.object().shape({
    puId: yup.string().required("Processing unit is required"),
  });

  const styles = {
    inputContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "20px",
      alignItems: "flex-start"
    },

    textLeft: {
      marginLeft: "15px"
    },

    card: {
      width: "20%",
      height: "100px",
    },

    inputField: {
      marginLeft: "10px",
      width: "125px",
    },

    excutiveField: {
      width: "130px"
    },

    pdf: {
      marginTop: "3px",
      cursor: "pointer"
    }
  };
  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}/${date}/${year}`;
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    getAllBrands()
    getAllProducts()
    getProcessingUnits();
    getPartners();
    getOperator();
  }, []);

  const getAllBrands = async () => {
    try {
      const response = await fetchAll(BRAND)
      if (response.data) {
        // console.log("brands", response.data.data)
        setBrands(response.data.data)
      }
    } catch (error) { }
  }

  const getKioskData = async (puId) => {
    try {
      setLoading(true);
      const response = await fetchAll(KIOSK, 'puid', puId);
      console.log(response.data.data)
      if (response.data && response.data.data) {
        let data = response.data.data.map(e => {
          return {
            ...e,
            items: e.items.map(f => {
              if (f.comboItems.length > 0) {
                return {
                  ...f,
                  comboType: true
                }
              } else {
                return {
                  ...f,
                  comboType: false
                }
              }
            })
          }
        })

        // setsalesReports(data);
        getDispatchData(puId, data)
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message);
      setLoading(false);
      setsalesReports([]);
    }
  };

  const getDispatchData = async (puId, kioskData) => {
    try {
      setLoading(true);
      const response = await fetchReportData(DISPATCH, 'puid', puId, 'date', moment(new Date()).format("YYYY-MM-DD"), moment(new Date()).add(1, 'days').format("YYYY-MM-DD"));
      if (response.data) {
        let data
        if (response.data.data) {
          data = response.data.data.map(e => {
            return {
              ...e,
              items: e.items.map(f => {
                if (f.comboItems.length > 0) {
                  return {
                    ...f,
                    comboType: true
                  }
                } else {
                  return {
                    ...f,
                    comboType: false
                  }
                }
              })
            }
          })
        } else {
          data = null
        }
        let inventory = []
        if (data && data.length) {
          setsalesReports([]);
          kioskData.forEach((e, i) => data.forEach(k => {
            if (e.kid === k.kid && moment(new Date()).startOf('day').isSame(moment(e.updatedAt).startOf('day'))) {

              inventory.push({
                ...e,
                stock: e.items,
                // items: k.items.map(f => { return { ...f, brandid: e.items[i].brandid } }),
                items: k.items.map(f => { return { ...f, brandid: e.items[0].brandid } }),
                executive: k.executive,
                dispatched: true,
                dispatchId: k._id
              })
            } else {
              if (moment(new Date()).startOf('day').isSame(moment(e.updatedAt).startOf('day')) && e.items.reduce((a, v) => v.quantity + a, 0) === 0) {
                inventory.push({
                  ...e,
                  stock: e.items.map(f => { return { ...f, quantity: 0 } }),
                  items: e.items.map(f => { return { ...f, quantity: 0 } }),
                  dispatched: false
                })
              }
            }
          }));
          kioskData.forEach(e => {
            if (!moment(new Date()).startOf('day').isSame(moment(e.updatedAt).startOf('day'))) {
              inventory.push({
                ...e,
                stock: e.items.map(f => { return { ...f, quantity: 0 } }),
                items: e.items.map(f => { return { ...f, quantity: 0 } }),
                dispatched: false
              })
            }
          });
          inventory = inventory.filter((v, i, a) => a.findIndex(v2 => (v.kid === v2.kid)) === i)
          setsalesReports(inventory);
        } else {
          setsalesReports([]);
          let data = kioskData.map(e => {
            return {
              ...e,
              stock: e.items.map(f => { return { ...f, quantity: 0 } }),
              items: e.items.map(f => { return { ...f, quantity: 0 } }),
              dispatched: false
            }
          })
          setsalesReports(data);
        }
      }
      setLoading(false);
    } catch (error) {
      setsalesReports([]);
      console.log(error)
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  const getProcessingUnits = async () => {
    try {
      const response = await fetchAll(PROCESSINGUNIT);
      if (response.data) {
        setProcessingUnits(response.data.data);
      }
    } catch (error) { }
  };

  const getPartners = async () => {
    try {
      const response = await fetchAll(PARTNER);
      if (response.data) {
        setPartner(response.data.data);
      }
    } catch (error) { }
  };

  const getOperator = async () => {
    try {
      const response = await fetchAll(OPERATOR);
      if (response.data) {
        setOperator(response.data.data);
      }
    } catch (error) { }
  };

  const getAllProducts = async () => {
    try {
      const response = await fetchAll(PRODUCT)
      if (response.data) {
        // console.log(response.data)
        setSkus(response.data.data)
      }
    } catch (error) { }
  }

  const getPartner = (prnid) => {
    if (partner && partner.length) {
      let data = partner.find(e => e.prnid == prnid)
      return `${data.firstname} ${data.lastname}`
    }
    return '';
  }

  const getBrand = (id) => {
    return brands && brands.length && brands.filter((e) => e._id === id)[0]?.name;
  };

  const handleChange = (id, e) => {
    const { name, value } = e.target
    let editData;
    if (name === 'executive') {
      editData = salesReport.map((item) =>
        item._id === id && name ? { ...item, 'opid': value } : item
      )
    }
    if (name === 'quantity') {
      editData = salesReport.map(item => {
        return {
          ...item,
          items: item.items.map(e => e._id === id ? ({ ...e, [name]: parseInt(value) }) : e)
        }
      })
    }

    setsalesReports(editData)
  }

  const handleDispatch = async (row) => {
    if (row && row.opid) {
      setLoading(true);
      let dispatchPayload = {
        puid: row.puid,
        opid: row.opid,
        kid: row.kid,
        brand: row.items && row.items.length ? row.items[0].brandid : '',
        area: row.address.address,
        items: row.items,
        // executive: row.executive,
        partner: getPartner(row.prnid),
        date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + ".000Z"
      }
      // let kioskPayload = row.items.map(e => {
      //   let stockItems = row.stock.filter(f => {
      //     if (f.skuid === e.skuid) {
      //       return f
      //     }
      //   })
      //   let quantity = e.quantity + stockItems.reduce((a, r) => a + r.quantity, 0)
      //   return {
      //     ...e,
      //     quantity: quantity
      //   }
      // })
      try {
        let dispatchResponse
        if (row.dispatched) {
          dispatchResponse = await update(DISPATCH, row.dispatchId, dispatchPayload);
        } else {
          dispatchResponse = await create(DISPATCH, dispatchPayload);
        }
        delete row.dispatchId
        const kioskResponse = await update(KIOSK, row._id, row);
        if (kioskResponse.data && kioskResponse.data.statusCode === 200 && dispatchResponse.data && dispatchResponse.data.statusCode === 200) {
          toast.success("Success");
          getKioskData(row.puid);
          socket.emit('orderPlaced')
          setLoading(false);
        } else {
          toast.error("Failed");
          setLoading(false);
        }

      } catch (error) {
        setLoading(false);
      }
    } else {
      toast.error("Executive is required.");
      setLoading(false);
    }


  }

  const handleSelectOP = (event) => {
    // let puName = processingUnits.find(e => e.puid === event.target.value).puname
    setOpid(event.target.value)
    console.log(event)
  };
  const handleSelect = (event) => {
    let puName = processingUnits.find(e => e.puid === event.target.value).puname
    setPuName(puName)
  };
  const exportPdf = () => {
    setTimeout(() => {
      let doc = new jsPDF('p', "mm", "a4");
      let cursor
      let dispatchDate = moment(new Date()).format('DD-MMM-YYYY')
      const heading = function (data) {
        doc.setFontSize(12);
        doc.text(dispatchDate, 15, 25,)
        doc.setFontSize(10);
        doc.setTextColor(198, 198, 198);
        let str = 'Page ' + data.pageCount;
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp;
        }
        doc.text(str, 100, doc.internal.pageSize.height - 12);
        // doc.text(date, 190, doc.internal.pageSize.height - 12);
      };
      const totalPagesExp = '{total_pages_count_string}';
      doc.text(`Dispatch Report :  ${puName}`, 15, 40,)

      // dispatch table
      autoTable(doc, {
        styles: {
          lineColor: 0,
          lineWidth: 5,
        },
        didDrawPage: heading,
        didDrawCell: function (data) {
          cursor = data.cursor
        },
        TableCell: 0,
        bodyStyles: {
          lineWidth: 0.2,
          lineColor: 0
        },
        margin: { top: 10, bottom: 20 },
        startY: 45,
        html: '#dispatch-table',
        theme: 'grid',
        headStyles: { fillColor: [128, 128, 128], lineWidth: 0.2, lineColor: 0 },
        footStyles: { fillColor: [128, 128, 128] }
      });

      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }
      doc.setProperties({
        title: "Dispatch Report"
      });
      window.open(doc.output('bloburl'))
    }, 1000);
  }

  return (
    <div className="container-fluid">
      {loading ? <Loader /> : null}
      <div className="row">
        <Formik
          validationSchema={reportsValidationSchema}
          initialValues={{
            puId: ""
          }}
          onSubmit={(values) => {
            getKioskData(values.puId);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>

              <div className="row mt-3">
                <spam><h4>{currentDate}</h4></spam>
                <div style={styles.inputContainer} className="col-sm-12">
                  <FormControl>
                    <Select
                      size="small"
                      placeholder='Select PU'
                      value={values.puId}
                      name="puId"
                      onChange={e => { handleSelect(e); handleChange(e) }}
                      error={errors.puId}
                      displayEmpty
                      style={{ width: "200px" }}
                    >
                      <MenuItem value="">
                        {processingUnits.length <= 0? 'Loading...' :'Select PU'}
                      </MenuItem>
                      {processingUnits && processingUnits.map((e, keyIndex) => {
                        return (<MenuItem key={keyIndex} value={e.puid}>{`${e.puid} - ${e.puname}`}</MenuItem>);
                      })}
                    </Select>
                    <FormHelperText styles={{ color: "#d32f2f" }}>
                      {errors.puId}
                    </FormHelperText>
                  </FormControl>

                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    variant="contained"
                  >
                    Submit
                  </Button>
                  {salesReport && salesReport.length ? <img src={pdfIcon} onClick={exportPdf} alt="pdf-img" style={styles.pdf} /> : null}
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-12">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Kiosk ID</TableCell>
                  <TableCell>Kiosk Area</TableCell>
                  {/* <TableCell>Partner</TableCell> */}
                  <TableCell>Franchise</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Items</TableCell>
                  <TableCell>Executive</TableCell>
                  {/* <TableCell>Remaining Items</TableCell> */}
                  <TableCell>Update</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salesReport && salesReport.length > 0 ? salesReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                  <TableRow
                    key={row.kid}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.kid}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.address.address}
                    </TableCell>
                    <TableCell>
                      {getPartner(row.prnid)}
                    </TableCell>
                    <TableCell>{getBrand(row.items[i]?.brandid)}</TableCell>
                    <TableCell>{<div>{row.items.map(i => <p key={i.skuid} style={{ display: 'flex', justifyContent: "space-between", marginRight: "20px" }}>{skus.filter(r => r._id === i.skuid)[0]?.skuName}
                      <TextField
                        style={styles.inputField}
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={i.quantity}
                        size="small"
                        onChange={(e) => handleChange(i._id, e)}
                        name="quantity"
                        placeholder="Qty"
                        variant="outlined"
                        disabled={i.comboType}
                      />
                    </p>)}</div>}</TableCell>
                    <TableCell>
                      {/* <TextField
                        style={styles.excutiveField}
                        value={row.executive}
                        size="small"
                        name="executive"
                        onChange={(e) => handleChange(row._id, e)}
                        placeholder="Executive"
                        variant="outlined"
                        error={row.executive ? false : true}
                      /> */}


                      <Select
                        size="small"
                        placeholder="Select Executive"
                        value={row.opid}
                        name="executive"
                        onChange={e => { handleSelectOP(e); handleChange(row._id, e) }}
                        // error={errors.puId}
                        displayEmpty
                        style={{ width: "200px" }}
                      >
                        <MenuItem value="">
                          Select Executive
                        </MenuItem>
                        {operator && operator.map((e, keyIndex) => {
                          return (<MenuItem key={keyIndex} value={e.opid}>{`${e.firstname} ${e.lastname}`}</MenuItem>);
                        })}
                      </Select>
                    </TableCell>
                    {/* <TableCell>
                      {row.stock.map(e => <p>{
                        <TextField
                          style={styles.inputField}
                          type="number"
                          value={e.quantity}
                          size="small"
                          placeholder="Qty"
                          variant="outlined"
                          disabled={true}
                        />
                      }</p>)}
                    </TableCell> */}
                    <TableCell>
                      {" "}
                      <IconButton onClick={() => handleDispatch(row)}>
                        <DoneOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: "center" }}>
                      <b>No data found</b>
                    </TableCell>
                  </TableRow>}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      20,
                      50,
                      70,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={8}
                    count={salesReport.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className="row" hidden={true}>
        <div className="col-sm-12">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" id="dispatch-table">
              <TableHead>
                <TableRow>
                  <TableCell>Kiosk ID</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Kiosk Area</TableCell>
                  <TableCell>Items</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Dispatch Executive Sign</TableCell>
                  <TableCell>Ops Partner Sign</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salesReport && salesReport.length > 0 ? salesReport.map((row, i) => (
                  <TableRow
                    key={row.kid}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.kid}
                    </TableCell>
                    <TableCell>{getBrand(row.items[i]?.brandid)}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.address.address}
                    </TableCell>
                    <TableCell>{row.items.map(i => <div key={i.skuid} >{skus.filter(r => r._id === i.skuid)[0]?.skuName} <br /><br />
                    </div>)}</TableCell>
                    <TableCell>
                      {row.items.map(i => <div key={i.skuid}>{i.quantity} <br /><br /></div>)}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: "center" }}>
                      <b>No data found</b>
                    </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};
export default Inventory;

import React from "react";
import { Formik } from "formik";
import { Container, Grid, Paper, TextField, Button, Link, CircularProgress, Box } from "@mui/material";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import bgImage from "../../assets/bg.jpg";
import { toast } from 'react-toastify';
import { login } from "../../api";
import logo from "../../assets/logo.png";

const Login = () => {
    const [loading, setLoading] = React.useState(false);
    let navigate = useNavigate();
    const loginValidationSchema = yup.object().shape({
        userId: yup.string().required("User Id is required"),
        password: yup.string().required("Password is required"),
    });

    const styles = {
        container: {
            backgroundImage: `url(${bgImage})`,
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        },

        error: {
            margin: "0 0 10px 10px",
            textAlign: "left",
            fontSize: "15px",
            color: "red",
        },

        logo: {
            width: "250px",
            marginBottom:20
        },

        loginHeader: {
            fontSize: "28px",
        }
    };

    return (
        <div style={styles.container}>
            <Formik
                validationSchema={loginValidationSchema}
                initialValues={{ userId: "", password: "" }}
                onSubmit={values => {
                    setLoading(true);
                    console.log('called');
                    try {
                        login(values.userId, values.password).then(
                            async res => {
                                // console.log(res.data.data, 'res');
                                if (res.status === 200) {
                                    let userData = JSON.stringify(res.data.data);
                                    localStorage.setItem('userData', userData);
                                    navigate(`/auth/partner-managment`)
                                    toast.success('Success');
                                    setLoading(false);
                                }
                            },
                            err => {
                                setLoading(false);
                                console.log(err.response.data, 'error');
                                toast.error(err.response.data.statusCode==400 ? err.response.data.message:err.message + 'Failure');
                                
                            },
                        );
                    } catch (error) {
                        setLoading(false);
                        console.log(error, 'error');
                    }
                }}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                    <Container maxWidth="sm">
                        <Grid container justifyContent="center">
                            <Paper
                                elevation={2}
                                sx={{ padding: 5 }}
                                style={{ width: "400px" }}
                            >
                                <Grid container spacing={2} direction="column">
                                    <Grid item align="center">
                                        <img src={logo} alt="My logo" style={styles.logo} />
                                    </Grid>
                                    <Grid item align="Left" style={styles.loginHeader}>
                                        Login
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            label="User Id"
                                            placeholder="User Id"
                                            variant="outlined"
                                            value={values.userId}
                                            onBlur={handleBlur("userId")}
                                            onChange={handleChange("userId")}
                                        />
                                        <p style={styles.error}>
                                            {touched.userId && errors.userId}
                                        </p>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            type={values.showPass ? "text" : "password"}
                                            fullWidth
                                            label="Password"
                                            placeholder="Password"
                                            variant="outlined"
                                            value={values.password}
                                            onBlur={handleBlur("password")}
                                            onChange={handleChange("password")}
                                        />
                                        <p style={styles.error}>
                                            {touched.password && errors.password}
                                        </p>
                                    </Grid>
                                    <Grid item>
                                    <Box sx={{ m: 1, position: 'relative' }}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleSubmit}
                                        >
                                            Login
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                        </Box>
                                    </Grid>
                                    <Grid item align="end">
                                        <Link
                                            href="#"
                                            onClick={() => navigate(`/forgot-password`)}
                                            underline="none"
                                        >
                                            Forgot Password?
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default Login;

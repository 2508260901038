import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"
import { fetchAll } from "../../api"
import TableFooter from "@mui/material/TableFooter"
import TablePagination from "@mui/material/TablePagination"
import { KIOSK, BRAND, PRODUCT,PARTNER } from "../../utils/constant"
import Checkbox from '@mui/material/Checkbox';
import Loader from "../../utils/Loader";

const BrandKioskManagement = () => {
  let navigate = useNavigate()
  const [page, setPage] = React.useState(0)
  const [loading, setLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const [kiosks, setKiosks] = React.useState([])
  const [brands, setBrands] = React.useState([])
  const [skus, setSkus] = React.useState([])
  const [partner, setPartner] = React.useState();
  const styles = {
    textLeft: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  }

  React.useEffect(() => {
    getAllBrands()
    getAllProducts()
    getAllKiosks()
    getPartners()
  }, [])

  const getAllKiosks = async () => {
    try {
      setLoading(true);
      const response = await fetchAll(KIOSK)
      if (response.data && response.data.data) {
        setKiosks(response.data.data)
        setLoading(false);
      } else {
        setKiosks([])
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
      setKiosks([])
      setLoading(false);
     }
  }
  const getAllBrands = async () => {
    try {
      const response = await fetchAll(BRAND)
      if (response.data) {
        // console.log("brands", response.data.data)
        setBrands(response.data.data)
      }
    } catch (error) { }
  }
  const getPartners = async () => {
    try {
      const response = await fetchAll(PARTNER);
      if (response.data) {
        setPartner(response.data.data);
      }
    } catch (error) { }
  };
  const getAllProducts = async () => {
    try {
      const response = await fetchAll(PRODUCT)
      if (response.data) {
        // console.log(response.data)
        setSkus(response.data.data)
      }
    } catch (error) { }
  }

  const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);

  const getPartner = (prnid) => {
    if (partner && partner.length) {
      let data = partner.find(e => e.prnid == prnid)
      return `${data.firstname} ${data.lastname}`
    }
    return '';
  }
  const getBrand = (id) => {
    return brands && brands.length && brands.filter((e) => e._id === id)[0].name;
  };

  return (
    <div className="container-fluid">
      {loading ? <Loader /> : null}
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col-sm-12">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                <TableCell>Kiosk ID</TableCell>
                  <TableCell>PU Name</TableCell>
                  {/* <TableCell>Partner ID</TableCell> */}
                  <TableCell>Brand</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {kiosks && kiosks.length > 0 ? kiosks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <TableRow key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
                   <TableCell component="th" scope="row">
                      {row.kid?row.kid:'-'}
                    </TableCell>
                    <TableCell> {getPartner(row.prnid)} </TableCell>
                    {/* <TableCell>
                      {row.prnid?row.prnid:'-'}
                    </TableCell> */}
                    <TableCell>{row.items.length?getBrand(row.items[0].brandid):'-'}</TableCell>
                    <TableCell><span>{`${row.address.address} \n ${row.address.city} \n ${row.address.postalcode} \n ${row.address.state}`}</span></TableCell>
                    <TableCell>{row.rentStartDate?new Date(row.rentStartDate).toDateString():'-'}</TableCell>
                    {/* <TableCell>{<NumberFormatBase currency={'USD'} format={row.deposit} />}</TableCell> */}

                    <TableCell><Checkbox checked={row.active} /></TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: "center" }}>
                      <b>No data found</b>
                    </TableCell>
                  </TableRow>}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      20,
                      50,
                      70,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={8}
                    count={kiosks.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div >)
}
export default BrandKioskManagement

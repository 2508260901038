import axios from "axios";

axios.defaults.baseURL = "https://delectable.appswise.tech/api/v1";

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.statusCode) {
      window.location.href('/')
  } else {
      return Promise.reject(error);
  }
});

export const login = (userId, password) => {
  const config = {
    headers: {
      username: userId,
      password: password,
      "Content-Type": "application/json",
    },
  };
  return axios.get("/user-management/login", config);
};

export const forgotPwd = (payload) => {
  return axios.post("/user-management/forgotpwd", payload);
};

const getAccessToken = () => {
  let accessToken = null;
  const authUser = JSON.parse(localStorage.getItem("userData"));
  if (authUser && authUser.token) {
    accessToken = authUser.token;
  }
  return accessToken;
};

const apiService = ({
  url = "",
  method = "GET",
  body = null,
  authToken = getAccessToken(),
  headers = {},
}) => {
  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
  if (!headers["Content-Type"]) {
    headers["Content-Type"] = "application/json";
  }

  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  } else delete headers.Authorization;
  return axios.request({
    url,
    method,
    headers,
    [dataOrParams]: body,
  });
};

export const uploadFile = (file) => {
  let payload = new FormData();
  payload.append("file", file);
  return apiService({
    url: "/file-management/upload",
    method: "POST",
    body: payload,
    headers: { "Content-Type": "multipart/form-data" }
  });
};

export const fetchAll = (category, attribute, value) => {
  let url = `${category}`;
  if (attribute && value) {
    url = url + `/?attr=${attribute}&val=${value}`;
  }
  return apiService({
    url: url,
    method: "GET"
  });
};

export const fetchOne = (category, id) => {
  return apiService({
    url: `${category}/${id}`,
    method: "GET"
  });
};

export const create = (category, payload) => {
  return apiService({
    url: category,
    method: "POST",
    body: payload
  });
};

export const update = (category, id, payload) => {
  return apiService({
    url: `${category}/${id}`,
    method: "PUT",
    body: payload
  });
};

export const deleteOne = (category, id) => {
  return apiService({
    url: `${category}/${id}`,
    method: "DELETE"
  });
};

export const fetchReportData = (
  category,
  attribute,
  value,
  dateAttr,
  fromDate,
  toDate
) => {
  return apiService({
    url: `${category}/?attr=${attribute}&val=${value}&attr=${dateAttr}&startDate=${fromDate}&endDate=${toDate}`,
    method: "GET"
  });
};



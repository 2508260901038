import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import "./App.css";
import PartnerManagement from "./components/PartnerManagement";
import BrandKioskManagement from "./components/BrandKioskManagement";
import OperatorKioskManagement from "./components/Operator/OperatorKioskManagement";
import Inventory from "./components/Inventory";
import Reports from "./components/Reports";
import Login from "./auth/Login/Login";
import ForgotPassword from "./auth/Forgot-Password/Forgot-Password";
import AddPartner from "./components/PartnerManagement/AddPartner";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OperatorManagement from "./components/Operator";
import AddOperator from "./components/Operator/AddOperator";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#eb6435",
      },
      secondary: {
        main: "#F3EFE0",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/auth" element={<Layout />}>
            <Route path="partner-managment" element={<PartnerManagement />} />
            <Route path="partner-managment/add" element={<AddPartner />} />
            <Route path="partner-managment/edit/:id" element={<AddPartner />} />
            <Route path="operator-managment" element={<OperatorManagement />} />
            <Route path="operator-managment/add" element={<AddOperator />} />
            <Route path="operator-managment/edit/:id" element={<AddOperator />} />
            <Route path="operatorkiosk-management" element={<OperatorKioskManagement />} />
            <Route
              path="brandkiosk-management"
              element={<BrandKioskManagement />}
            />
            <Route path="dsdr" element={<Inventory />} />
            <Route path="reports" element={<Reports />} />
          </Route>
        </Routes>
      </Router>
      <div>
      <ToastContainer hideProgressBar={true} autoClose={2000}/>
      </div>
    </ThemeProvider>
  );
}

export default App;

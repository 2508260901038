import React from "react";
import { Formik } from "formik";
import { Container, Grid, Paper, TextField, Button, Link, CircularProgress, Box } from "@mui/material";
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
import bgImage from "../../assets/bg.jpg";
import logo from "../../assets/logo.png";
import { toast } from 'react-toastify';
import { forgotPwd } from "../../api";

const ForgotPassword = () => {
    const [loading, setLoading] = React.useState(false);
    let navigate = useNavigate();
    const forgotPwdValidationSchema = yup.object().shape({
        email: yup.string().required('User Id is required'),
    });

    const styles = {
        container: {
            backgroundImage: `url(${bgImage})`,
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        },

        error: {
            margin: "0 0 10px 10px",
            textAlign: "left",
            fontSize: "15px",
            color: "red"
        },

        logo: {
            width: "200px",
        },

        forgotPwdHeader: {
            fontSize: "24px"
        }
    }

    return (
        <div style={styles.container}>
            <Formik
                validationSchema={forgotPwdValidationSchema}
                initialValues={{ email: '' }}
                onSubmit={(values) => {
                    console.log(values)
                    try {
                        forgotPwd(values).then(
                            async res => {
                                if (res.status === 200) {
                                    toast.success(res.data.message);
                                    setLoading(false);
                                }
                            },
                            err => {
                                console.log(err, 'error');
                                toast.error(err.response.data.message);
                                setLoading(false);
                            },
                        );
                    } catch (error) {
                        console.log(error, 'error');
                        toast.error(error.message);
                        setLoading(false);
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                    <Container maxWidth="sm">
                        <Grid container justifyContent="center">
                            <Paper elevation={2} sx={{ padding: 5 }} style={{ width: "400px" }}>
                                <Grid container spacing={2} direction="column" >
                                    <Grid item align="center">
                                        <img src={logo} alt="My logo" style={styles.logo} />
                                    </Grid>
                                    <Grid item align="Left" style={styles.forgotPwdHeader}>
                                        Forgot Password
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            label="Enter User Id"
                                            placeholder="Enter User Id"
                                            variant="outlined"
                                            value={values.email}
                                            onBlur={handleBlur('email')}
                                            onChange={handleChange('email')}
                                        />
                                        <p style={styles.error}>
                                            {touched.email && errors.email}
                                        </p>
                                    </Grid>

                                    <Grid item>
                                        <Box sx={{ position: 'relative' }}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                disabled={loading}
                                                onClick={handleSubmit}
                                            >
                                                Request Password
                                            </Button>
                                            {loading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item align="center">
                                        <Link href="#" onClick={() => navigate(`/`)} underline="none">
                                            Return to login
                                        </Link>
                                    </Grid>

                                </Grid>
                            </Paper>
                        </Grid>
                    </Container>
                )}
            </Formik>
        </div>
    )
}

export default ForgotPassword;
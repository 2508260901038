import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { fetchAll, fetchReportData } from "../../api";
import { Typography, Box, MenuItem, Select, Button, FormControl, TextField, FormHelperText } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as yup from "yup";
import { ORDER, PROCESSINGUNIT, BRAND, PRODUCT, DISPATCH } from "../../utils/constant"
import { toast } from 'react-toastify';
import moment from 'moment';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "../../utils/Loader";

const Reports = () => {
  const [salesReport, setsalesReports] = React.useState([]);
  const [processingUnits, setProcessingUnits] = React.useState([]);
  const [brands, setBrands] = React.useState([])
  const [skus, setSkus] = React.useState([])
  const [loading, setLoading] = React.useState(false);
  const [dispatch, setDispatch] = React.useState([])
  const [saleQtytotal, setSaleQtyTotal] = React.useState(0);
  const [saleAmountTotal, setSaleAmountTotal] = React.useState(0);
  const [wastage, setwastage] = React.useState(0);
  const [brandData, setBrandData] = React.useState([])
  const [salesData, setSalesData] = React.useState([])
  const [comboList, setComboList] = React.useState([])

  const reportsValidationSchema = yup.object().shape({
    puId: yup.string().required("Processing unit is required"),
    fromDate: yup.string().required("From date is required"),
    toDate: yup.string().required("To date is required"),
  });

  const styles = {
    inputContainer: {
      width: "200px"
    },

    textLeft: {
      display: "flex",
      gap: "1rem",
      justifyContent: "flex-end",
      padding: 0
    },

    card: {
      width: "20%",
      height: "100px",
    },

    box: {
      border: "1px solid black",
      padding: "5px"
    },

    listStyles: {
      listStyle: 'none',
      padding: 0
    }
  };


  React.useEffect(() => {
    getAllBrands()
    getAllProducts()
    getProcessingUnits();
    getAllSales()
  }, []);

  const getAllSales = async () => {
    try {
      const response = await fetchAll(ORDER);
      if (response.data.data) {
        response.data.data = response.data.data.filter(e => e.status === 'Completed').filter(f => f.items.length)
        setSalesData(response.data.data)
        getAllDispatch(response.data.data)
        // let qtyTotal = 0;
        // let saleTotal = 0;

        // salesReport.forEach(element => {
        //   qtyTotal += parseInt(element.items.reduce((a, v) => v.quantity + a, 0))
        //   saleTotal += parseFloat(element.totalamount)
        // });

      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message);
    }
  }

  const getAllDispatch = async (data) => {
    try {
      const response = await fetchAll(DISPATCH);
      if (response.data) {
        let dispatchQty = 0;
        let dispatchData = response.data.data;
        dispatchData.forEach(element => {
          dispatchQty += parseInt(element.items.reduce((a, v) => v.quantity + a, 0))
        });
        let saleQty = 0;
        data.forEach(element => {
          saleQty += parseInt(element.items.reduce((a, v) => v.quantity + a, 0))
        });

        let wastagePercentile = ((dispatchQty - saleQty) / dispatchQty * 100).toFixed(2);
        setwastage(wastagePercentile);
      }
    } catch (error) {
      console.log(error)
      setwastage(0);
    }
  }

  const getSalesReports = async (data) => {
    try {
      setLoading(true);
      const response = await fetchReportData(ORDER, 'puid', data.puId, 'date', moment(new Date(data.fromDate)).format("YYYY-MM-DD"), moment(new Date(data.toDate)).add(1, 'days').format("YYYY-MM-DD"));
      // console.log(response)
      if (response.data.data) {

        response.data.data = response.data.data.filter(e => e.status === 'Completed').filter(f => f.items.length)
        // console.log(response.data.data)

        // console.log(response.data.data)
        let dataResp = response.data.data
        // let tempArray = []
        let combolist = []
        // console.log(dataResp)

        let i = 0
        dataResp.forEach(e => {
          let j = 0
          let orderitems = []
          e.items.forEach(f => {
            if (f.isCombo === true) {
              let k = 0
              f.comboItems.forEach(g => {
                // console.log(g.quantity, f.quantity)
                k = k + 1
                orderitems.push({
                  ...g,
                  price: 0,
                  quantity: (g.quantity * f.quantity)
                })
                if (f.comboItems.length == k) {
                  j = j + 1
                }
              })
              orderitems.push(f)
              combolist.push(f);
            } else {
              orderitems.push(f)
              j = j + 1
            }
          })

          if (e.items.length == j) {
            e.items = orderitems
            // combolist.push(orderitems)
            i = i + 1
          }

        })

        if (dataResp.length == i) {
          // console.log(combolist)
          setComboList(combolist.length > 0 ? combolist : [])
        }
        // dataResp.forEach(e => {
        //     let i = 0
        //     e.items.forEach((f) => {
        //       i = i + 1
        //       if (f.isCombo === true) {
        //         let j = 0
        //         f.comboItems.forEach(g => {
        //           console.log(g.quantity, f.quantity)
        //           j = j + 1
        //           tempArray.push({
        //             ...g,
        //             price: 0,
        //             quantity: (g.quantity * f.quantity)
        //           })
        //           console.log(tempArray)
        //           if (e.items.length == i && f.comboItems.length == j) {
        //             // tempArray.forEach(item => {
        //             //   const obj = combolist.find(o => o.skuid === item.skuid);
        //             //   if (obj) {
        //             //     console.log(obj.quantity, item.quantity)
        //             //     obj.quantity = obj.quantity + item.quantity;
        //             //   } else {
        //             //     combolist.push(item);
        //             //   }
        //             // });
        //             console.log(tempArray)

        //           //   e.items = e.items.concat(tempArray)
        //             setComboList(combolist.length > 0 ? combolist : [])
        //           }
        //         })
        //       }
        //     })
        //   })


        let salesReport = response.data.data.filter(e => e.brand.length)
        setsalesReports(salesReport);
        // salesReport.forEach(element => {
        //   qtyTotal += parseInt(element.items.reduce((a, v) => v.quantity + a, 0))
        //   saleTotal += parseFloat(element.totalamount)
        // });


        // setSaleQtyTotal(qtyTotal);
        // setSaleAmountTotal(saleTotal);
        setLoading(false);
        getDispatchData(data, salesReport);
      } else {
        setLoading(false);
        setsalesReports([]);
        setBrandData([])
        // setSaleQtyTotal(0);
        // setSaleAmountTotal(0);
        // setwastage(0)
        toast.error('No data found!');
      }
    } catch (error) {
      setLoading(false);
      console.log(error)
      toast.error(error.response.data.message);
      setsalesReports([]);
      setBrandData([])
      // setSaleQtyTotal(0);
      // setwastage(0)
      // setSaleAmountTotal(0);
    }
  };

  const getTotalSummary = (data) => {
    let finalTotalData = []
    data.forEach(e => {
      e.items.forEach(f => {
        if (finalTotalData.length && finalTotalData.filter(m => m.id == f.skuid).length) {
          let index = finalTotalData.findIndex(m => m.id == f.skuid)
          finalTotalData[index].quantity = finalTotalData[index].quantity + f.quantity
          finalTotalData[index].price = finalTotalData[index].quantity * f.price
          finalTotalData[index].isCombo = f.isCombo
          if (e.takeaway) {
            finalTotalData[index]['totalamount'] = finalTotalData[index]['totalamount'] + Math.round((f.quantity * f.price) + (f.quantity + f.price * 5) / 100 * f.quantity)
          } else {
            finalTotalData[index]['totalamount'] = finalTotalData[index]['totalamount'] + Math.round(f.quantity * f.price)
          }
        } else {
          let skuData = skus.filter(s => s._id === f.skuid)[0]
          let obj = {
            id: f.skuid,
            quantity: f.quantity,
            name: skuData?.skuName,
            image: skuData?.image,
            icon: skuData?.icon,
            kid: e.kid,
            price: f.price
          }
          obj['totalamount'] = 0
          if (e.takeaway) {
            obj['totalamount'] = obj['totalamount'] + Math.round((f.quantity * f.price) + (f.quantity + f.price * 5) / 100 * f.quantity)
          } else {
            obj['totalamount'] = obj['totalamount'] + Math.round(f.quantity * f.price)
          }
          if (obj) {
            finalTotalData.push(obj)
          }
        }

      })
    })
    // console.log(finalTotalData,"finalTotalData")
    return finalTotalData
  }

  const getDispatchData = async (data, salesData) => {
    try {
      setLoading(true);
      const response = await fetchReportData(DISPATCH, 'puid', data.puId, 'date', moment(new Date(data.fromDate)).format("YYYY-MM-DD"), moment(new Date(data.toDate)).add(1, 'days').format("YYYY-MM-DD"));
      if (response.data) {
        // let dispatchQty = 0;
        let dispatchData = response.data.data;
        setDispatch(dispatchData);
        // dispatchData.forEach(element => {
        //   dispatchQty += parseInt(element.items.reduce((a, v) => v.quantity + a, 0))
        // });

        // let wastagePercentile = ((dispatchQty - sQtotal) / dispatchQty * 100).toFixed(2);
        // setwastage(wastagePercentile);

        const data = []
        brands.forEach(brand => {
          let filteredSalesData = salesData.filter(sale => sale.brand.includes(brand.name))
          let kiosks = new Set([...dispatchData.map(e => e.kid)])
          let kioskSalesData = []
          let kioskDispatchData = []
          let totalSalesData = getTotalSummary(filteredSalesData)
          let totalDispatchData = getTotalSummary(dispatchData)
          kiosks.forEach(kiosk => {

            let kioskSales = filteredSalesData.filter(sale => sale.kid === kiosk)
            kioskSalesData.push(getTotalSummary(kioskSales))
            let kioskDispatch = dispatchData.filter(sale => sale.kid === kiosk)
            kioskDispatchData.push(getTotalSummary(kioskDispatch))
          })

          data.push({
            name: brand.name,
            id: brand._id,
            dispatchData: kioskDispatchData,
            salesData: kioskSalesData,
            totalSalesData: totalSalesData,
            totalDispatchData: totalDispatchData
          })


        })
        // console.log(data, "data")
        setBrandData([...data])

        setLoading(false);
      }
    } catch (error) {
      console.log(error)
      setLoading(false);
      // setwastage(0);
    }
  }

  const getProcessingUnits = async () => {
    try {
      const response = await fetchAll(PROCESSINGUNIT);
      if (response.data) {
        setProcessingUnits(response.data.data);
      }
      // console.log(response);
    } catch (error) { }
  };

  const getAllProducts = async () => {
    try {
      const response = await fetchAll(PRODUCT)
      if (response.data) {
        // console.log(response.data)
        setSkus(response.data.data)
      }
    } catch (error) { }
  }

  const getAllBrands = async () => {
    try {
      const response = await fetchAll(BRAND)
      if (response.data) {
        setBrands(response.data.data)
      }
    } catch (error) { }
  }

  const getTotalAmount = () => {
    return salesData.reduce((a, v) => (a + v.totalamount), 0)
  }

  const getTotalQty = () => {
    let qtyTotal = 0;
    salesData.forEach(element => {
      qtyTotal += parseInt(element.items.reduce((a, v) => v.quantity + a, 0))
    });
    return qtyTotal;
  }
  return (
    <div className="container-fluid">
      {loading ? <Loader /> : null}
      <Box
        sx={{
          display: 'flex',
          width: "100%",
          gap: 3,
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 0,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            height: 100,
            p: 2
          },
        }}
      >
        <Paper elevation={3} >
          <Typography variant="h7" sx={{ fontWeight: 500 }}>
            Total Sale Amount
          </Typography>
          <Typography variant="h7" sx={{ ml: 0.5 }}>
            {getTotalAmount()}
          </Typography>
        </Paper>
        <Paper elevation={3} sx={{ fontWeight: 500 }}>
          <Typography variant="h7">
            Total Sale Quantity
          </Typography>
          <Typography variant="h7" sx={{ ml: 0.5 }}>
            {getTotalQty()}
          </Typography>
        </Paper>
        <Paper elevation={3} sx={{ fontWeight: 500 }}>
          <Typography variant="h7">
            Wastage
          </Typography>
          <Typography variant="h7" sx={{ ml: 0.5 }}>
            {wastage} %
          </Typography>
        </Paper>
      </Box>
      <div className="row">
        <Formik
          validationSchema={reportsValidationSchema}
          initialValues={{
            puId: "",
            fromDate: new Date(),
            toDate: new Date(),
          }}
          onSubmit={(values) => {
            getSalesReports(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>

              <div className="row mt-5">
                <div style={styles.textLeft} className="col-sm-12">
                  <div>
                    <FormControl style={styles.inputContainer}>
                      <Select
                        size="small"
                        placeholder="Select PU ID"
                        value={values.puId}
                        name="puId"
                        onChange={handleChange}
                        error={errors.puId}
                        displayEmpty
                      >
                        <MenuItem value="">
                          Select PU ID
                        </MenuItem>
                        {processingUnits && processingUnits.map((e, keyIndex) => {
                          return (<MenuItem key={keyIndex} value={e.puid}>{`${e.puid} - ${e.puname}`}</MenuItem>);
                        })}
                      </Select>
                      <FormHelperText styles={{ color: "#d32f2f" }}>
                        {errors.puId}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div style={styles.inputContainer}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="From Date"
                        value={values.fromDate}
                        name="fromDate"
                        onBlur={handleBlur("fromDate")}
                        onChange={(date) => setFieldValue("fromDate", date)}
                        inputFormat="DD-MM-YYYY"
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div style={styles.inputContainer}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="To Date"
                        value={values.toDate}
                        name="toDate"
                        onBlur={handleBlur("toDate")}
                        onChange={(date) => setFieldValue("toDate", date)}
                        inputFormat="DD-MM-YYYY"
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-12">
          {
            brandData.map(brand => (
              <Accordion key={brand.name} sx={{ mb: 2 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <br />
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">

                    <TableBody>
                      <TableRow

                        sx={{ "&:last-child td, &:last-child th": { border: 0 }, backgroundColor: 'whitesmoke' }}
                      >
                        <TableCell>Brand</TableCell>
                        <TableCell>Total Sale: &#8377;{(brand.totalSalesData.reduce((a, v) => (a + (v.totalamount)), 0)).toFixed(2)}</TableCell>
                        <TableCell>Total Orders: {brand.totalSalesData.reduce((a, v) => (a + v.quantity), 0)}</TableCell>
                        <TableCell>Wastage: {
                          ((brand.totalDispatchData.reduce((a, v) => (a + v.quantity), 0) - brand.totalSalesData.reduce((a, v) => (a + v.quantity), 0))
                            / brand.totalDispatchData.reduce((a, v) => (a + v.quantity), 0) * 100).toFixed(2)

                        } %</TableCell>
                      </TableRow>
                      <TableRow
                        key={brand.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell>{brand.name}</TableCell>
                        <TableCell>
                          {
                            brand.dispatchData.length ? brand.dispatchData[0].map(e => (
                              <p key={e.id}>{e.name}</p>
                            )) : '-'
                          }
                        </TableCell>
                        <TableCell>
                          {
                            brand.dispatchData.length ? brand.dispatchData[0].map(e => {
                              let sale = brand.totalSalesData.filter(f => f.id === e.id)
                              if (sale && sale.length) {
                                return <p key={e.id}>Total Sale - <span style={styles.box}><b>{sale[0].quantity}</b></span></p>
                              } else {
                                return <p key={e.id}>Total Sale - <span style={styles.box}><b>0</b></span></p>
                              }

                            }) : '-'
                          }

                        </TableCell>
                        <TableCell>
                          {/* <ol style={{ listStyle: 'none' }}> */}
                          {
                            brand.totalDispatchData.map(e => {

                              let sale = brand.totalSalesData.filter(f => f.id === e.id)[0]
                              return (<p key={e.id}>Wastage - <span style={styles.box}><b>{sale && sale.quantity ? e.quantity > 0 ? ((e?.quantity - (sale && sale.quantity ? sale.quantity : 0)) / e?.quantity * 100).toFixed(2) : 0 : 100}%</b></span> </p>)

                            }

                            )
                          }
                          {/* </ol> */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ margin: 1 }}>
                    <br />


                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Kiosk ID</TableCell>
                          <TableCell>Sale</TableCell>
                          <TableCell>Orders</TableCell>
                          <TableCell>Wastage</TableCell>
                          <TableCell>Dispatch Qty</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {brand.dispatchData && brand.dispatchData.length > 0 ? brand.dispatchData.map((rowData) => (
                          <TableRow key={rowData[0]?.kid}>
                            <TableCell>
                              {rowData[0]?.kid}
                            </TableCell>
                            <TableCell style={{ width: 90 }}>
                              &#8377;{
                                brand.salesData.filter(f => f.map(e => e.kid).includes(rowData[0]?.kid)).length ?
                                  (brand.salesData.filter(f => f.map(e => e.kid).includes(rowData[0]?.kid))[0].reduce((a, v) => (a + v.totalamount), 0)).toFixed(2) : 0
                              }
                            </TableCell>
                            <TableCell>
                              <ol style={styles.listStyles}>
                                {
                                  rowData.map(e => {
                                    const getSaleQun = (id) => {
                                      let sale = brand.salesData.filter(f => f.map(e => e.kid).includes(rowData[0]?.kid))
                                      if (sale.length) {
                                        sale = sale[0].filter(e => e.id == id)
                                        return sale && sale[0]?.quantity ? sale[0].quantity : 0
                                      } else {
                                        return 0
                                      }
                                    }

                                    const getcomboQt = (id) => {
                                      if (comboList.length) {
                                        let tempArray = comboList.map(e=>{
                                          return {
                                            comboItems : e.comboItems.map(f=>{
                                              return {
                                                ...f,
                                                quantity: e.quantity * f.quantity
                                              }
                                            })
                                          }
                                        })
                                        let flatArray = tempArray.flatMap(e => e.comboItems).filter(f => f.skuid)
                                        const consolidated = {};
                                        for (const item of flatArray) {
                                          const skuid = item.skuid;
                                          const quantity = item.quantity;
                                          if (consolidated.hasOwnProperty(skuid)) {
                                            consolidated[skuid] += quantity;
                                          } else {
                                            consolidated[skuid] = quantity;
                                          }
                                        }
                                        const consolidatedArray = Object.entries(consolidated).map(([skuid, quantity]) => ({
                                          skuid,
                                          quantity,
                                        }));
                                        let comboqt = consolidatedArray.filter(e => e.skuid == id)
                                        return comboList && comboqt[0]?.quantity ? `(+ ${comboqt[0].quantity} Combo)` : ''
                                      } else {
                                        return ''
                                      }
                                    }

                                    return (<li key={e.id}>{e.name} : <b>{getSaleQun(e.id)} {getcomboQt(e.id)}</b> </li>)
                                  })
                                }
                              </ol>
                            </TableCell>
                            <TableCell>
                              <ol style={styles.listStyles}>
                                {

                                  rowData.map(e => {


                                    const getSaleQun = (id) => {
                                      let sale = brand.salesData.filter(f => f.map(e => e.kid).includes(rowData[0]?.kid))
                                      if (sale.length) {
                                        sale = sale[0]?.filter(e => e.id == id)
                                        return sale && sale[0]?.quantity ? sale[0].quantity : 0
                                      } else {
                                        return 0
                                      }

                                    }

                                    return (
                                      <li key={e.id}>{e.name} :
                                        <b> {
                                          getSaleQun(e.id) ? e.quantity > 0 ? ((e.quantity - getSaleQun(e.id)) / e.quantity * 100).toFixed(2) : 0 : 100

                                        }</b>

                                        %</li>
                                    )
                                  })


                                }
                              </ol>
                            </TableCell>

                            <TableCell>
                              <ol style={styles.listStyles}>
                                {
                                  rowData.map(e => (
                                    <li key={e.id}>{e.name} : <b>{e.quantity}</b> </li>
                                  ))
                                }
                              </ol>
                            </TableCell>

                          </TableRow>
                        )) : ''}


                      </TableBody>
                    </Table>

                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          }


        </div>

      </div>
    </div>
  );
};
export default Reports;

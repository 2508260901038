import * as yup from "yup";
import * as React from "react";
import { TextField, Typography, Button, IconButton } from "@mui/material";
import Dropzone from "react-dropzone";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Formik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { update, uploadFile } from "../../../api";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../../utils/Loader";
import { create, fetchOne } from "../../../api";
import { OPERATOR } from "../../../utils/constant";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as moment from "moment";

const AddOperator = () => {
  let pancardRegExp = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  let pincodeRegExp = /^[1-9][0-9]{5}$/;
  let { id } = useParams();
  let navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [pageLoad, setPageLoad] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState();
  const [checkedAsSameAdress, setCheckedAsSameAdress] = React.useState(false);
  const [operatorData, setOperatorData] = React.useState({})
  const validationSchema = yup.object().shape({
    firstname: yup.string().required("First Name is required"),
    lastname: yup.string().required("Last Name is required"),
    gender: yup.string().required("Gender is required"),
    phone: yup.string().min(10, 'Please enter valid  Mobile Number').max(10, 'Please enter valid  Mobile Number').required("Mobile Number is required"),
    dob: yup.string().required("Date of Birth is required"),
    aadhar: yup.string().min(12, "Please enter valid Aadhar Number").max(12, "Please enter valid Aadhar Number").required("Aadhar Number is required"),
    pan: yup.string().matches(pancardRegExp, "Please enter valid Pan Number").required("Pan Number is required"),
    address: yup.string().required("Address is required"),
    photo: yup.string().required("Profile Image is required"),
    idProof: yup.string().required("Id Proof Document is required"),
    password: yup.string().required("Password is required"),
  });

  React.useEffect(() => {
    getOperatorData();
  }, []);


  const styles = {
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 20,
    },
  };

  const formatFileName = (str) => {
    return str.substring(str.lastIndexOf("/") + 1);
  };

  const removeEmptyAttributes = (obj) => {
    Object.entries(obj).forEach(([key, val]) =>
      (val && typeof val === 'object') && removeEmptyAttributes(val) ||
      (val === null || val === undefined || val === "") && delete obj[key]
    );
    return obj;
  };

  const submit = async (values) => {
    try {
      setLoading(true);
      let payload = {
        firstname: values.firstname,
        lastname: values.lastname,
        phone: values.phone,
        dob: moment(new Date(values.dob)).format("MM/DD/YYYY"),
        gender: values.gender,
        address: {
          address: values.address,
          // city: values.cCity,
          // state: values.cState,
          postalcode: values.postalcode,
        },
        idProof: values.idProof,
        aadhar: values.aadhar,
        pan: values.pan,
        photo: values.photo,
        deleted: false,
        password: values.password,
      };
      payload = removeEmptyAttributes(payload)
      // console.log(payload)
      let response = {};
      if (!id) {
        response = await create(OPERATOR, payload);
      } else {
        response = await update(OPERATOR, id, payload);
      }
      if (response) {
        toast.success(response.data.message);
        setLoading(false);
        navigate("/auth/operator-managment");
      }
    }
    catch (error) {
      console.log(error, 'error');
      if (error.response.data.data.replace(/[^a-zA-Z ]/g, "").toLowerCase().includes('phone')) {
        toast.error('Phone number already exists !');
      } else if (error.response.data.statusCode === 401) {
        toast.warn('Session Expired !')
        localStorage.removeItem("userData");
        navigate("/")
      } else {
        toast.error(error.response.data.message);
      }

      setLoading(false);
    }
  };

  const getOperatorData = async () => {
    if (!id) {
      setInitialValues({
        firstname: "",
        lastname: "",
        phone: "",
        dob: new Date("01-05-2010"),
        gender: "",
        aadhar: "",
        pan: "",
        address: "",
        photo: "",
        idProof: "",
        password: "",
      });
      setPageLoad(true);
    } else {
      try {
        setLoading(true);
        const response = await fetchOne(OPERATOR, id);
        if (response) {
          let data = { ...response.data.data };
          setOperatorData(data)
          data.postalcode = data.address.postalcode;
          // data.cCity = data.cAddress.city;
          data.dob = new Date(data.dob);
          data.address = data.address.address;
          setInitialValues(data);

          // console.log(data, "data");
          setLoading(false);
          setPageLoad(true);
        }
      } catch (error) { }
    }
  };

  const catchError = (error) => {
    if (error && error.response.data.statusCode === 401) {
      toast.warn('Session Expired !')
      localStorage.removeItem("userData");
      navigate("/")
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  return (
    <div className="container-fluid">
      {loading ? <Loader /> : null}
      <Typography color="primary" variant="h5">
        <IconButton onClick={() => navigate(`/auth/operator-managment`)}>
          <ArrowBackIcon />
        </IconButton>
        Add New Operator
        {/* Add New Operator */}
      </Typography>
      <br />
      {pageLoad ? (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            submit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <Typography
                style={{ marginBottom: 10 }}
                color="primary"
                variant="h6"
              >
                Personal Details
              </Typography>

              <div className="row">
                <div style={styles.inputContainer} className="col-sm-3">
                  <Typography variant="caption">
                    First Name <span className="required">*</span>
                  </Typography>
                  <TextField
                    name="firstname"
                    value={values.firstname}
                    onChange={handleChange}
                    placeholder="First Name"
                    size="small"
                    variant="outlined"
                    onBlur={handleBlur("firstname")}
                    error={touched.firstname && errors.firstname ? true : false}
                    helperText={touched.firstname && errors.firstname}
                  />
                </div>
                <div style={styles.inputContainer} className="col-sm-3">
                  <Typography variant="caption">
                    Last Name <span className="required">*</span>
                  </Typography>
                  <TextField
                    value={values.lastname}
                    size="small"
                    onChange={handleChange}
                    name="lastname"
                    placeholder="Last Name"
                    variant="outlined"
                    onBlur={handleBlur("lastname")}
                    error={touched.lastname && errors.lastname ? true : false}
                    helperText={touched.lastname && errors.lastname}
                  />
                </div>

                <div style={styles.inputContainer} className="col-sm-3">
                  <Typography variant="caption">
                    Date Of Birth <span className="required">*</span>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={values.dob}
                      name="dob"
                      onBlur={handleBlur("dob")}
                      error={touched.dob && errors.dob ? true : false}
                      onChange={(date) => setFieldValue("dob", date)}
                      maxDate={new Date("01-05-2010")}
                      inputFormat="DD-MM-YYYY"
                      renderInput={(params) => (
                        <TextField
                          helperText={touched.dob && errors.dob}
                          size="small"
                          {...params}

                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div style={styles.inputContainer} className="col-sm-3">
                  <Typography variant="caption">
                    Gender <span className="required">*</span>
                  </Typography>
                  <FormControl>
                    <Select
                      size="small"
                      placeholder="Gender"
                      value={values.gender}
                      name="gender"
                      onChange={handleChange}
                      onBlur={handleBlur("gender")}
                      error={touched.gender && errors.gender ? true : false}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                    <FormHelperText styles={{ color: "#d32f2f" }}>
                      {touched.gender && errors.gender}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div style={styles.inputContainer} className="col-sm-3">
                  <Typography variant="caption">
                    Mobile Number <span className="required">*</span>
                  </Typography>
                  <TextField
                    size="small"
                    type="number"
                    onChange={handleChange}
                    value={values.phone}
                    placeholder="Mobile Number"
                    name="phone"
                    variant="outlined"
                    onBlur={handleBlur("phone")}
                    error={touched.phone && errors.phone ? true : false}
                    helperText={touched.phone && errors.phone}
                  />
                </div>
                <div style={styles.inputContainer} className="col-sm-3">
                  <Typography variant="caption">
                    Aadhar Card <span className="required">*</span>
                  </Typography>
                  <TextField
                    size="small"
                    type="number"
                    onChange={handleChange}
                    value={values.aadhar}
                    placeholder="Aadhar Number"
                    name="aadhar"
                    variant="outlined"
                    onBlur={handleBlur("aadhar")}
                    error={touched.aadhar && errors.aadhar ? true : false}
                    helperText={touched.aadhar && errors.aadhar}
                  />
                </div>
                <div style={styles.inputContainer} className="col-sm-3">
                  <Typography variant="caption">
                    Pan Card <span className="required">*</span>
                  </Typography>
                  <TextField
                    size="small"
                    onChange={handleChange}
                    value={values.pan.toUpperCase()}
                    placeholder="Pan Number"
                    name="pan"
                    variant="outlined"
                    onBlur={handleBlur("pan")}
                    error={touched.pan && errors.pan ? true : false}
                    helperText={touched.pan && errors.pan}
                  />
                </div>
                <div style={styles.inputContainer} className="col-sm-3">
                  <Typography variant="caption">
                    Login Password <span className="required">*</span>
                  </Typography>
                  <TextField
                    size="small"
                    onChange={handleChange}
                    name="password"
                    value={values.password}
                    placeholder="Login Password"
                    variant="outlined"
                    onBlur={handleBlur("password")}
                    error={touched.password && errors.password ? true : false}
                    helperText={touched.password && errors.password}
                  />
                </div>
              </div>
              <Typography
                style={{ marginBottom: 10 }}
                color="primary"
                variant="h6"
              >
                Address
              </Typography>
              <div className="row">
                <div style={styles.inputContainer} className="col-sm-3">
                  <Typography variant="caption">
                    Address <span className="required">*</span>
                  </Typography>
                  <TextField
                    size="small"
                    onChange={handleChange}
                    value={values.address}
                    placeholder="Address"
                    name="address"
                    variant="outlined"
                    onBlur={handleBlur("address")}
                    error={touched.address && errors.address ? true : false}
                    helperText={touched.address && errors.address}
                  />
                </div>

                <div style={styles.inputContainer} className="col-sm-3">
                  <Typography variant="caption">
                    Pincode <span className="required">*</span>
                  </Typography>
                  <TextField
                    type="number"
                    size="small"
                    placeholder="Pincode"
                    onChange={handleChange}
                    value={values.postalcode}
                    variant="outlined"
                    name="postalcode"
                    onBlur={handleBlur("postalcode")}
                    error={touched.postalcode && errors.postalcode ? true : false}
                    helperText={touched.postalcode && errors.postalcode}
                  />
                </div>
              </div>
              <Typography
                style={{ marginBottom: 10 }}
                color="primary"
                variant="h6"
              >
                Documents
              </Typography>

              <div className="row">
                <div style={styles.inputContainer} className="col-sm-6">
                  <Typography variant="caption">
                    Profile Image <span className="required">*</span>
                  </Typography>
                  <Dropzone
                    multiple={false}
                    accept={{
                      "image/*": [".jpeg", ".png"],
                    }}
                    onDrop={async (file) => {
                      // console.log(file);
                      try {
                        setLoading(true);
                        const MIN_FILE_SIZE = 1024
                        const fileSizeKiloBytes = file[0].size / 1024
                        if (fileSizeKiloBytes > MIN_FILE_SIZE) {
                          toast.warn('File size should be less than 1 MB !')
                          setLoading(false);
                          return;
                        }
                        const response = await uploadFile(file[0]);
                        if (response) {
                          setFieldValue("photo", response.data.data.Location);
                          setLoading(false);
                        } else {
                          setLoading(false);
                        }
                      } catch (error) {
                        catchError(error)
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input type="input" {...getInputProps()} />

                          {values.photo ? (
                            <div className="selected-file">
                              {formatFileName(values.photo)}
                              <IconButton
                                onClick={() => setFieldValue("photo", "")}
                                color="error"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ) : (
                            <div>
                              'Drag and drop file here, or click to select file'
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <Typography variant="caption" color="error">
                    {touched.photo && errors.photo}
                  </Typography>
                </div>
                <div style={styles.inputContainer} className="col-sm-6">
                  <Typography variant="caption">
                    Id Proof <span className="required">*</span>
                  </Typography>
                  <Dropzone
                    multiple={false}
                    accept={{
                      "image/*": [".jpeg", ".png"],
                      "application/pdf": [".pdf"],
                    }}
                    onDrop={async (file) => {
                      // console.log(file);
                      try {
                        setLoading(true);
                        const MIN_FILE_SIZE = 1024
                        const fileSizeKiloBytes = file[0].size / 1024
                        if (fileSizeKiloBytes > MIN_FILE_SIZE) {
                          toast.warn('File size should be less than 1 MB !')
                          setLoading(false);
                          return;
                        }
                        const response = await uploadFile(file[0]);
                        if (response) {
                          setFieldValue("idProof", response.data.data.Location);
                          setLoading(false);
                        } else {
                          setLoading(false);
                        }
                      } catch (error) {
                        catchError(error)
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          {values.idProof ? (
                            <div className="selected-file">
                              {formatFileName(values.idProof)}
                              <IconButton
                                onClick={() => setFieldValue("idProof", "")}
                                color="error"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ) : (
                            <div>
                              'Drag and drop file here, or click to select file'
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <Typography variant="caption" color="error">
                    {touched.idProof && errors.idProof}
                  </Typography>
                </div>

                <br />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    variant="contained"
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ marginLeft: 20, background: "gray" }}
                    variant="contained"
                    onClick={() => navigate("/auth/operator-managment")}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </>
          )}
        </Formik>
      ) : null}
    </div>
  );
};
export default AddOperator;

import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import Diversity2Icon from '@mui/icons-material/Diversity2';
import CountertopsIcon from '@mui/icons-material/Countertops';
import InsightsIcon from "@mui/icons-material/Insights";
import ArticleIcon from "@mui/icons-material/Article";
import { Outlet, NavLink } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const drawerWidth = 340;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Layout() {
  let navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const logout = () => {
    localStorage.removeItem("userData");
    navigate("/");
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuItems = [
    {
      // name: "Partner Management",
      name: "Franchise Management",
      icon: <Diversity3Icon />,
      path: "/auth/partner-managment",
    },{
      // name: "Partner Management",
      name: "Operator Management",
      icon: <Diversity2Icon />,
      path: "/auth/operator-managment",
    },
    {
      name: "Operator & Kiosk Management",
      icon: <CountertopsIcon />,
      path: "/auth/operatorkiosk-management",
    },
    {
      name: "Brand & Kiosk Management",
      icon: <WorkspacePremiumIcon />,
      path: "/auth/brandkiosk-management",
    },
    {
      name: "Daily Stock Delivery Record",
      icon: <ArticleIcon />,
      path: "/auth/dsdr",
    },
    { name: "Sales Report", icon: <InsightsIcon />, path: "/auth/reports" },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {menuItems[selectedMenuIndex].name}
          </Typography>
          <Button
            variant="text"
            sx={{ ml: "auto", mt: 2 }}
            style={{ color: "white" }}
            onClick={logout}
          >
            Logout
          </Button>
          {/* <Typography variant="h6" noWrap component="div" >
            Logout
          </Typography> */}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <img alt="logo" style={{ width: 220, marginLeft: 8 }} src={Logo} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((menu, index) => (
            <NavLink
              key={index}
              className={({ isActive }) => (isActive ? "active-link" : "menu")}
              to={menu.path}
            >
              <ListItem
                onClick={() => setSelectedMenuIndex(index)}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>{menu.icon}</ListItemIcon>
                  <ListItemText primary={menu.name} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3,background:'whitesmoke',minHeight:'100vh' }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}

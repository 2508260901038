import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { deleteOne, fetchAll } from "../../api";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { PARTNER } from "../../utils/constant";
import Loader from "../../utils/Loader";
import { toast } from "react-toastify";
import * as moment from "moment";

const PartnerManagement = () => {
  let navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };
  const [partners, setPartners] = React.useState([]);
  const styles = {
    textLeft: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  };


  React.useEffect(() => {
    getAllPartners();
  }, []);

  const getAllPartners = async () => {
    try {
      setLoading(true);
      const response = await fetchAll(PARTNER);
      if (response.data) {
        const data = response.data.data.filter(e => !e.deleted)
        setPartners(data);
        setLoading(false);
      }
      // console.log(response);
    } catch (error) { }
  };


  const deletePartner = async (id) => {
    try {
      setLoading(true);
      const response = await deleteOne(PARTNER, id);
      if (response.data) {
        toast.success(response.data.message);
        getAllPartners()
        setLoading(false);
      }
      // console.log(response);
    } catch (error) { }
  };


  return (
    <div className="container-fluid">
       {loading ? <Loader /> : null}
      <div className="row">
        <div style={styles.textLeft} className="col-sm-12">
          <Button onClick={() => navigate("add")} variant="contained">
            {/* Add Partner */}
            Add Franchise
          </Button>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-12">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>Partner ID</TableCell> */}
                  <TableCell>Franchise ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Date of Birth</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partners && partners.length > 0 ? partners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.prnid ? row.prnid : '-'}
                    </TableCell>
                    <TableCell >
                      {`${row.firstname} ${row.lastname}`}
                    </TableCell>
                    <TableCell>{row.gender}</TableCell>
                    <TableCell>{row.email ? row.email : '-'}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{moment(new Date(row.dob)).format('DD MMM, YYYY')}</TableCell>
                    <TableCell>
                      {" "}
                      <IconButton onClick={() => navigate(`edit/${row._id}`)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell>
                      {" "}
                      <IconButton onClick={() => deletePartner(row._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: "center" }}>
                      <b>No data found</b>
                    </TableCell>
                  </TableRow>}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      20,
                      50,
                      70,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={8}
                    count={partners.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};
export default PartnerManagement;
